<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
          <h2 class="brand-text text-primary ml-1">
            Visi Nonni
          </h2>
        </b-link>
        <b-card-text class="mb-2 text-jcenter">
          Para continuarmos o processo por favor insira o código que foi enviado para seu e-mail.
        </b-card-text>
          <b-form-input
            class="mb-2"
            v-model="dateCode.code"
            placeholder="Gj2G9"
          />
          <b-button
            variant="primary"
            block
            type="submit"
            @click="codeFunction"
          >
            Verificar
          </b-button>

        <b-card-text class="text-center mt-2">
          <span>Não recebeu o e-mail? </span>
          <b-link :to="{name:'forgotPassword'}">
            <span>Inserir novamente o e-mail</span>
          </b-link>
        </b-card-text>
      </b-card>
    <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapState } from "vuex";

export default {
  components: {
    VuexyLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      dateCode: {
        code: null,
        email: JSON.parse(localStorage.email).email
      },
    }
  },
  computed: {
  },
  methods: {
    ...mapActions("resetPassword", ["codeRecover"]),
    codeFunction() {
      this.codeRecover({
        ...this.dateCode,
      })
       .then(() => {
         localStorage.removeItem(["email"]);
        this.$router.push('/novasenha') 
      });
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
